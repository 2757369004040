import * as React from "react"

import Home from '../components/Home/Home'
import Layout from "../layouts"

const IndexPage = () => {
  return (
    <Layout pageTitle="😁">
      <Home />
    </Layout>
  )
}

export default IndexPage